// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-capp-js": () => import("./../../../src/pages/capp.js" /* webpackChunkName: "component---src-pages-capp-js" */),
  "component---src-pages-casper-2020-js": () => import("./../../../src/pages/casper-2020.js" /* webpackChunkName: "component---src-pages-casper-2020-js" */),
  "component---src-pages-cloudware-business-js": () => import("./../../../src/pages/cloudware-business.js" /* webpackChunkName: "component---src-pages-cloudware-business-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learning-center-js": () => import("./../../../src/pages/learning-center.js" /* webpackChunkName: "component---src-pages-learning-center-js" */),
  "component---src-pages-politica-campanhas-js": () => import("./../../../src/pages/politica-campanhas.js" /* webpackChunkName: "component---src-pages-politica-campanhas-js" */),
  "component---src-pages-politica-privacidade-js": () => import("./../../../src/pages/politica-privacidade.js" /* webpackChunkName: "component---src-pages-politica-privacidade-js" */),
  "component---src-pages-pos-js": () => import("./../../../src/pages/pos.js" /* webpackChunkName: "component---src-pages-pos-js" */),
  "component---src-pages-pos-subscrever-js": () => import("./../../../src/pages/pos-subscrever.js" /* webpackChunkName: "component---src-pages-pos-subscrever-js" */),
  "component---src-pages-termos-e-condicoes-js": () => import("./../../../src/pages/termos-e-condicoes.js" /* webpackChunkName: "component---src-pages-termos-e-condicoes-js" */),
  "component---src-pages-toconline-js": () => import("./../../../src/pages/toconline.js" /* webpackChunkName: "component---src-pages-toconline-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

